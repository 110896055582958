import { AdaptiveUi } from "@avinet/adaptive-ui-core";
import Form from "@avinet/adaptive-ui-core/form/Form";
import { TranslatorProvider } from "@avinet/adaptive-ui-translate";
import theme from "./config/theme";
import Address from "./pages/AddressPage";
import Calc from "./pages/CalcPage";
import { FormRecord } from "./types/FormRecord";
import "./App.scss";
import { BrowserRouter, Route, Routes } from "react-router-dom";

function App() {
  const onSubmit = () => {};

  return (
    <AdaptiveUi theme={theme}>
      <TranslatorProvider
        translations={{
          locale: "en",
          Form: {
            optional: "optional",
            condimentLabel: "Condiment",
            condimentsLabel: "Condiments",
            formatLabel: "Format",
            addressLabel: "Hvor befinner bygget ditt seg?",
            houseTypeCategoryLabel: "Ditt type bygg",
            remoteHeatingLabel: "Hvordan er bygget oppvarmet",
            energyStandardLabel: "TEK standard",
            areaLabel: "Størrelse i kvadratmeter",
            lifetimeLabel: "Forventet levetid",
          },
          buildingType: {
            boligblokk: "Boligblokk",
            bolighus: "Bolighus",
            industri: "Industri/logistikk",
            kontor: "Kontorbygg",
          },
          remoteHeating: {
            med_oppvarming: "Elektrisk (panelovner e.l.)",
            uten_oppvarming: "Fjernvarme",
            varmepumpe: "Varmepumpe",
          },
          HouseType: {
            0: "Bolighus med oppvarming",
            1: "Bolighus uten oppvarming",
            2: "Boligblokk med oppvarming",
            3: "Boligblokk uten oppvarming",
            4: "Kontor uten oppvarming",
            5: "Industri/logistikk (uten oppvarming)",
          },
          EnergyStandard: {
            tek97: "Eldre standard",
            tek10: "Dagens standard (TEK 10/17)",
            passiv: "Passivhus",
          },
          RoofType: {
            flat: "Flatt tak",
            gable: "Skrått tak",
          },
          RoofGableShadow: {
            1.0: "Ingen skygge",
            0.95: "Noe skygge",
            0.7: "Mye skygge",
          },
          RoofFlatMounting: {
            10: "Øst/vest 10°",
            15: "Sørvendt 15°",
          },
          RoofFlatShadow: {
            1.0: "Ingen skygge",
            0.95: "Noe skygge",
            0.7: "Mye skygge",
          },
        }}
      >
        <Form
          onSubmit={onSubmit}
          defaultValues={
            {
              roofGableOrientation: 0,
              roofGableAngle: 25,
              lifetime: 25,
              totalCostPower: 1,
              priceEdited: false,
              effectEdited: false,
              capitalCost: 0,
            } as FormRecord
          }
        >
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Address />} />
              <Route path="/calc" element={<Calc />} />
            </Routes>
          </BrowserRouter>
        </Form>
      </TranslatorProvider>
    </AdaptiveUi>
  );
}

export default App;
