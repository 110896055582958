import List from "@avinet/adaptive-ui-core/form/controls/List";
import { useFormApi } from "@avinet/adaptive-ui-core/form/useForm";
import { useEffect, useMemo } from "react";
import eastWest from "../assets/east_west_mounting.svg";
import south from "../assets/south_mounting.svg";
import Help from "../components/Help";

const id = "roofFlatMounting";

export default function RoofType() {
  const items = useMemo(() => [{ value: 10 }, { value: 15 }], []);

  const { getValue, setValue } = useFormApi();
  const currentValue = getValue(id);

  useEffect(() => {
    switch (currentValue) {
      case 10:
        setValue("roofGableAngle", 0);
        break;
      case 15:
        setValue("roofGableAngle", 15);
        break;
      default:
        setValue("roofGableAngle", null);
    }
  }, [currentValue, setValue]);

  const lottie = useMemo(() => {
    switch (currentValue) {
      case 10:
        return eastWest;
      case 15:
        return south;
    }
  }, [currentValue]);

  return (
    <div className="page">
      <h2>Orientering</h2>
      <div className="flex-layout">
        <List
          className="flex-items"
          langSet="RoofFlatMounting"
          label={null}
          id={id}
          items={items}
        />
        <img src={lottie} alt="" />
      </div>
      <Help title="Hva er forskjellen på orienteringene?">
        <p>
          Øst/vest er mest vanlig (og ofte billigste løsning per kWh produsert),
          men dersom man har mye takareal tilgjengelig og ønsker høyest mulig
          produksjon per solcellepanel kan sørvendt montasje velges.
        </p>
      </Help>
    </div>
  );
}
