import { useEffect, useRef } from "react";
import { useFormApi } from "@avinet/adaptive-ui-core/form/useForm";
import roof from "../assets/gable_angle.json";
import Lottie from "lottie-react";
import Column from "@avinet/adaptive-ui-core/layout/Column";
import Help from "../components/Help";
const id = "roofGableAngle";

export default function RoofType() {
  const { getValue, setValue } = useFormApi();
  const lottieRef = useRef<any>(null);

  useEffect(() => {
    lottieRef.current.goToAndStop(25, true);
  }, []);

  return (
    <>
      <Column>
        <h2>{getValue(id)}°</h2>
        <div className="slider-container">
          <Lottie
            lottieRef={lottieRef}
            animationData={roof}
            loop={false}
            autoplay={false}
          />
          <input
            type="range"
            min="0"
            max="80"
            value={getValue(id)}
            className="slider"
            onChange={(e) => {
              setValue(id, parseInt(e.target.value));
              lottieRef.current.goToAndStop(parseInt(e.target.value), true);
            }}
          />
        </div>
      </Column>
      <Help title="Hva er normal takvinkel?">
        <p>
          Takvinkelen er helningen på taket ditt. Typisk i Norge er ca 30 grader
          for eneboliger. Google streetview er et verktøy som kan brukes for å
          måle dette for ditt tak.
        </p>
      </Help>
    </>
  );
}
