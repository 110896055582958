import Column from "@avinet/adaptive-ui-core/layout/Column";
import useDebounce from "@avinet/adaptive-ui-core/hooks/useDebounce";
import "./AddressPage.scss";
import Lottie from "lottie-react";
import home from "../assets/home.json";
import { useCallback, useEffect, useState } from "react";
import { useFormApi } from "@avinet/adaptive-ui-core/form/useForm";
import Input from "@avinet/adaptive-ui-core/form/controls/Input";
import request from "superagent";
import Header from "../components/Header";
import Help from "../components/Help";
import { useNavigate } from "react-router-dom";

interface SearchResults {
  address: string;
  municipality: string;
  lat: number;
  lon: number;
}

export default function Address() {
  const [results, setResults] = useState<SearchResults[]>([]);
  const [error, setError] = useState<string | null>(null);

  const { getValue, setValue } = useFormApi();

  const address = useDebounce(getValue("address"), 1000);

  let navigate = useNavigate();

  useEffect(() => {
    if (!address) {
      setResults([]);
    }

    setError(null);
    request
      .get(
        `https://ws.geonorge.no/adresser/v1/sok?side=0&utkoordsys=4258&sok=${address}*&treffPerSide=5&asciiKompatibel=true&sokemodus=AND`
      )
      .then((res) => {
        setResults(
          res.body.adresser
            .sort((a: any, b: any) => {
              const fa = `${a.adressenavn}, ${a.kommunenavn}`.toLowerCase();
              const fb = `${a.adressenavn}, ${a.kommunenavn}`.toLowerCase();

              if (fa < fb) {
                return 1;
              }
              if (fa > fb) {
                return -1;
              }
              return 0;
            })
            .sort((a: any, b: any) => a.nummer - b.nummer)
            .map((a: any) => ({
              address: a.adressetekst,
              municipality: a.kommunenavn.toLowerCase(),
              lat: a.representasjonspunkt.lat,
              lon: a.representasjonspunkt.lon,
            }))
        );
      })
      .catch((err) => {
        console.error(err);
        setError("En feil oppstod ved henting av adresse");
      });
  }, [address]);

  const handleAddressSelected = useCallback(
    (a: any) => {
      setValue("selectedAddress", a);
      setValue("lat", a.lat);
      setValue("lon", a.lon);
      navigate("/calc");
    },
    [setValue, navigate]
  );

  return (
    <div className="address-page">
      <Header />
      <Column align="center">
        <div className="content">
          <h1>Hvor befinner bygget seg?</h1>
          <div className="lottie home">
            <Lottie animationData={home} loop={false} />
          </div>
          <Input
            id="address"
            type="text"
            placeholder="Skriv inn addresse her"
            required
          />
          {error && <div className="error">{error}</div>}
          <div className="search-results">
            {results.map((r, idx) => (
              <button
                key={idx}
                className="btn-list"
                onClick={() => handleAddressSelected(r)}
              >
                {r.address},{" "}
                <span className="capitalize">{r.municipality}</span>
              </button>
            ))}
          </div>
          <Help title="Hva brukes dette til?">
            <p>
              Vi bruker adressen din til å finne ut hvor mye solinnstråling det
              har vært ved ditt bygg for hver enkelt time over 4 år slik at vi
              kan finne ut hvor mye du vil kunne produsere av solenergi. Vi
              bruker 4 år slik at du får inkludert både de gode og dårlige årene
              for solinnstråling.
            </p>
          </Help>
        </div>
      </Column>
    </div>
  );
}
