import List from "@avinet/adaptive-ui-core/form/controls/List";
import { useMemo } from "react";
import { useFormApi } from "@avinet/adaptive-ui-core/form/useForm";
import Page from "../components/Page";
import flat from "../assets/flat.svg";
import gable from "../assets/gable.svg";
import RoofFlatMounting from "./RoofFlatMounting";
import RoofGableAngle from "./RoofGableAngle";
import RoofGableOrientation from "./RoofGableOrientation";
import RoofGableShadow from "./RoofShadow";
import Help from "../components/Help";
import "./RoofType.scss";

const id = "roofType";

export default function RoofType() {
  const items = useMemo(() => [{ value: "flat" }, { value: "gable" }], []);
  const { getValue } = useFormApi();
  const currentValue = getValue(id);

  const lottie = useMemo(() => {
    switch (currentValue) {
      case "flat":
        return flat;
      case "gable":
        return gable;
    }
  }, [currentValue]);

  return (
    <div className="roof-type">
      <Page>
        <h1>Taktype</h1>
        <div className="flex-layout">
          <List
            className="flex-items"
            langSet="RoofType"
            label={null}
            id={id}
            items={items}
          />
          <img src={lottie} alt="" />
        </div>
        <Help title="Hvordan vet jeg taktypen min?">
          <p>
            Flatt tak er vanlig på f.eks. boligblokker, næringsbygg og
            lagerbygg. Skråtak er vanlig på de fleste eneboliger og mindre bygg.
            Med flatt tak menes tak som har under 4 grader helning.
          </p>
        </Help>
      </Page>

      {currentValue === "gable" && (
        <>
          <Page>
            <h2>Takvinkel</h2>
            <RoofGableAngle />
          </Page>
          <Page>
            <RoofGableOrientation />
          </Page>
        </>
      )}
      {currentValue === "flat" && <RoofFlatMounting />}
      <RoofGableShadow />
    </div>
  );
}
