import NumberInput from "@avinet/adaptive-ui-core/form/controls/NumberInput";
import Row from "@avinet/adaptive-ui-core/layout/Row";
import Help from "../components/Help";
import Page from "../components/Page";
import Lottie from "lottie-react";
import priceLottie from "../assets/price.json";
import { useEffect } from "react";
import { useFormApi } from "@avinet/adaptive-ui-core/form/useForm";

const idPurchasePrice = "price";
const idTotalCostPower = "totalCostPower";
const idCapitalCost = "capitalCost";

export default function Price() {
  const { getValue, setValue } = useFormApi();
  const price = getValue("price");
  const priceEdited = getValue("priceEdited");

  useEffect(() => {
    if (price !== undefined) {
      setValue("priceEdited", true);
    }
  }, [price, setValue]);

  return (
    <Page>
      <h1>Hva koster solcelleanlegget?</h1>
      {}{" "}
      <Row>
        <div className="flex-item">
          <Row align="bottom">
            <NumberInput
              id={idPurchasePrice}
              locale="nb"
              type="integer"
              label={
                "Innkjøpspris" +
                (!priceEdited ? " (Beregnet ut i fra ønsket effekt)" : "")
              }
              required={true}
            />
            <span className="field-label">kr</span>
          </Row>
          <Row align="bottom">
            <NumberInput
              id={idTotalCostPower}
              locale="nb"
              type="decimal"
              label="Totalkostnad for strøm. inkl.nettleie"
              required={true}
            />
            <span className="field-label">kr/kWh</span>
          </Row>
          <Row align="bottom">
            <NumberInput
              id={idCapitalCost}
              locale="nb"
              type="decimal"
              label="Rente"
              required={true}
            />
            <span className="field-label">%</span>
          </Row>
        </div>
        <Lottie className="lottie" animationData={priceLottie} loop={false} />
      </Row>
      <Help title="Mer om kostnader">
        <p>
          Innkjøpspris er prisen for et komplett solcelleanlegg fra en
          leverandør, inkl. mva. Her har vi lagt inn en defaultverdi som er
          basert på de valg du har tatt lengre opp. Ved inntasting av ny kostnad
          eller endring av andre verdier vil ikke kostnaden endres. Vil du ha en
          ny beregning av kostnad må du starte kalkulatoren på nytt. Dersom du
          ikke har fått pris fra leverandør, kan du teste med følgende tall:
          <ul>
            <li>Bolig, 2-6 kWp: [effekt i kWp] * 15 000 kr</li>
            <li>Lite næringsbygg, 10-30 kWp: [effekt i kWp] * 12 500 kr</li>
            <li>Stort næringsbygg: 50-200 kWp: [effekt i kWp] * 10 000 kr</li>
          </ul>
        </p>
        <p>
          Totalkostnad for strøm, inkl. nettleie er det du betaler i nettleie og
          strømutgifter for hver enkelt kWh som brukes i bygget. Det er dette
          som er besparelsen om man installerer et solcelleanlegg. Det vil
          variere fra landsdel til landsdel og fra år til år hva denne
          totalkostnaden vil ligge på. Vi foreslår at denne settes til 1 kr/kWh.
          For strøm som selges på nettet vil man, avhengig av hvilken avtale man
          har, kun få betalt for strømprisen, og dermed en lavere verdi. Her har
          vi antatt halvparten av totalkostnaden for strøm.
        </p>
        <p>
          Rente kan enten være en kalkulasjonsrente som gjenspeiler den
          avkastningen man vil ha på investeringen, eller det kan være den
          lånerenten man må betale for et lån som dekker investeringen.
        </p>
      </Help>
    </Page>
  );
}
