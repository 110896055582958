import { useEffect, useState, useMemo } from "react";
import request from "superagent";
import { useFormApi } from "@avinet/adaptive-ui-core/form/useForm";
import useDebounce from "@avinet/adaptive-ui-core/hooks/useDebounce";
import { FormRecord } from "../types/FormRecord";
import Icon from "@avinet/adaptive-ui-core/ui/Icon";
import Calculate from "../Calculations";
import Result from "../types/Result";
import downloadAsCsv from "../csv";
import Loading from "../components/Loading";
import Help from "../components/Help";
import HiddenContent from "../components/AdvancedResultsDropDown";

const startYear = 2013;
const endYear = startYear + 3;
const inputDebounce = 1000;

interface Calculations {
  productionLifeTime?: number;
  totalSelfConsumption?: number;
  result?: Result[];
}

interface Errors {
  series: boolean;
  calculations: boolean;
  consumption: boolean;
}

export default function ResultPage() {
  const { getState, getValue, setValue } = useFormApi();
  const formState = getState() as FormRecord;
  const [hourly, setHourly] = useState<any>([]);
  const [consumption, setConsumption] = useState<number[]>([]);
  const [loadingSeries, setLoadingSeries] = useState(false);
  const [loadingCalculations, setLoadingCalculations] = useState(false);
  const [loadingConsumption, setLoadingConsumption] = useState(false);
  const [errors, setErrors] = useState<Errors>({
    series: false,
    calculations: false,
    consumption: false,
  });
  const lat = getValue("lat");
  const lon = getValue("lon");
  const houseTypeCategory = useDebounce(
    formState.houseTypeCategory,
    inputDebounce
  );
  const remoteHeating = useDebounce(formState.remoteHeating, inputDebounce);
  const energyStandard = useDebounce(formState.energyStandard, inputDebounce);
  const roofAngle = useDebounce(formState.roofGableAngle, inputDebounce);
  const roofOrientation = useDebounce(
    formState.roofGableOrientation,
    inputDebounce
  );
  const roofShadow = useDebounce(formState.roofShadow, inputDebounce);
  const lifetime = useDebounce(formState.lifetime, inputDebounce);
  const effect = useDebounce(formState.effect, inputDebounce);
  const purchasePrice = useDebounce(formState.price, inputDebounce);
  const totalCostPower = useDebounce(formState.totalCostPower, inputDebounce); //Totalkostnad for strøm. Inkl.nettleie //kr/kWh
  const capitalCost = useDebounce(formState.capitalCost, inputDebounce);
  const area = useDebounce(formState.area, inputDebounce); //Kvadratmeter

  const idEnergyStandard = useMemo<any>(() => {
    if (!(houseTypeCategory && remoteHeating && energyStandard)) {
      return null;
    }
    return `${houseTypeCategory}_${remoteHeating}_${energyStandard}`;
  }, [houseTypeCategory, remoteHeating, energyStandard]);

  useEffect(() => {
    if (!idEnergyStandard) {
      return;
    }
    setLoadingConsumption(true);
    setErrors((val) => {
      return { ...val, consumption: false };
    });
    request
      .get(`/data/${idEnergyStandard}.json`)
      .then((res) => {
        setConsumption(res.body);
        setLoadingConsumption(false);
      })
      .catch((err) => {
        console.error(err);
        setErrors((val) => {
          return { ...val, consumption: true };
        });

        setLoadingConsumption(false);
      });
  }, [idEnergyStandard]);

  useEffect(() => {
    if (
      !(
        !(effect === null || effect === undefined) &&
        formState.roofType &&
        !(roofAngle === null || roofAngle === undefined)
      )
    ) {
      console.log("Missing data. skipping");
      return;
    }
    if (formState.priceEdited) {
      console.log("price already edited set. skipping");
      return;
    }

    let estimatedPrice;
    if (formState.roofType === "flat") {
      if (roofAngle === 0) {
        estimatedPrice =
          effect * (20.323 * Math.pow(effect, -0.149) - 0.6) * 1000;
      }
      if (roofAngle === 15) {
        estimatedPrice =
          effect * (20.323 * Math.pow(effect, -0.149) + 0, 4) * 1000;
      }
    } else if (formState.roofType === "gable") {
      estimatedPrice =
        effect * (20.323 * Math.pow(effect, -0.149) + 0, 4) * 1000;
    }

    setValue("price", estimatedPrice);

    console.log({ estimatedPrice });
  }, [formState.roofType, roofAngle, formState.priceEdited, effect, setValue]);

  const totalEnergyConsumption = useMemo(() => {
    if (consumption.length === 0 || !area) {
      return null;
    }
    const totalConsumption = consumption.reduce((value, current) => {
      return (value = value + current);
    });

    return (totalConsumption * area) / 4;
  }, [consumption, area]);

  useEffect(() => {
    if (
      lat == null ||
      lon == null ||
      startYear == null ||
      endYear == null ||
      effect == null ||
      roofAngle == null ||
      roofOrientation == null
    ) {
      return;
    }

    setLoadingSeries(true);
    setErrors((val) => {
      return { ...val, series: false };
    });

    request
      .get(
        `/api/seriescalc?lat=${lat}&lon=${lon}&startyear=${startYear}&endyear=${endYear}&outputformat=json&pvcalculation=1&peakpower=${effect}&loss=14&angle=${roofAngle}&aspect=${roofOrientation}&raddatabase=PVGIS-ERA5`
      )
      .then((res) => {
        setHourly(res.body.outputs.hourly.map((h: any) => h.P));
        setLoadingSeries(false);
      })
      .catch((err) => {
        console.error(err);
        setErrors((val) => {
          return { ...val, series: true };
        });
        setLoadingSeries(false);
      });
  }, [lat, lon, effect, roofAngle, roofOrientation]);

  const filledOut = useMemo(() => {
    console.log({
      area,
      capitalCost,
      hourly,
      consumption,
      lifetime,
      purchasePrice,
      roofShadow,
      totalCostPower,
      effect,
      roofAngle,
      roofOrientation,
    });

    console.log(
      "all valid",
      area ??
        capitalCost ??
        hourly ??
        consumption ??
        lifetime ??
        purchasePrice ??
        roofShadow ??
        totalCostPower ??
        effect ??
        roofAngle ??
        roofOrientation ??
        true
    );

    return true;
  }, [
    effect,
    roofAngle,
    roofOrientation,
    area,
    capitalCost,
    hourly,
    consumption,
    lifetime,
    purchasePrice,
    roofShadow,
    totalCostPower,
  ]);

  const { productionLifeTime, result, totalSelfConsumption } =
    useMemo<Calculations>(() => {
      if (
        area === undefined ||
        capitalCost === undefined ||
        hourly.length === 0 ||
        consumption.length === 0 ||
        lifetime === undefined ||
        purchasePrice === undefined ||
        roofShadow === undefined ||
        totalCostPower === undefined
      ) {
        return { productionLifeTime: 0, result: [], totalSelfConsumption: 0 };
      }

      try {
        setLoadingCalculations(true);
        setErrors((val) => {
          return { ...val, calculations: false };
        });

        const calcs = Calculate(
          hourly,
          purchasePrice,
          roofShadow,
          consumption,
          area,
          lifetime,
          totalCostPower,
          capitalCost / 100
        );

        setLoadingCalculations(false);

        return calcs;
      } catch (error) {
        setLoadingCalculations(false);
        setErrors((val) => {
          return { ...val, calculations: true };
        });
        return {};
      }
    }, [
      area,
      capitalCost,
      hourly,
      consumption,
      lifetime,
      purchasePrice,
      roofShadow,
      totalCostPower,
    ]);

  const payback = useMemo(() => {
    if (!result || result.length === 0) {
      return "-";
    }

    const positiveIdx = result.findIndex((r: Result) => {
      return r.totalDiscountedWithSales > 0;
    });

    if (positiveIdx === -1) {
      return "-";
    }

    return `${positiveIdx}`;
  }, [result]);

  const reducedEmissions = useMemo(() => {
    if (!effect || !productionLifeTime) {
      return;
    }
    return 0.0001677 * productionLifeTime - 0.68 * effect;
  }, [productionLifeTime, effect]);

  const selfUsage = useMemo(() => {
    if (!totalSelfConsumption || !productionLifeTime) {
      return null;
    }
    return (totalSelfConsumption / productionLifeTime) * 100;
  }, [totalSelfConsumption, productionLifeTime]);

  const loading = useMemo(() => {
    return loadingConsumption || loadingSeries || loadingCalculations;
  }, [loadingConsumption, loadingSeries, loadingCalculations]);

  return (
    <div className="result">
      {loading && <Loading />}
      <div className="result-content">
        {errors.calculations ||
        errors.consumption ||
        errors.series ||
        !result ? (
          <div className="error">
            En feil oppstod ved beregning av resultater
          </div>
        ) : (
          <>
            <h2>Resultat:</h2>
            <div className="result-group">
              <div className="help-text">Kostnader tjent inn etter</div>
              <h3>{payback}</h3>
              <span className="unit"> år</span>
            </div>
            <HiddenContent title="Avanserte resultater">
              <div className="result-group">
                <div>Årlig gjennomsnittlig produksjon</div>
                <h3>
                  {productionLifeTime
                    ? (productionLifeTime / lifetime).toLocaleString("nb", {
                        maximumFractionDigits: 0,
                        minimumFractionDigits: 0,
                      })
                    : "-"}
                </h3>
                <span className="unit"> kWh</span>
              </div>
              <div className="result-group">
                <div>Produksjon over levetid</div>
                <h3>
                  {productionLifeTime
                    ? productionLifeTime.toLocaleString("nb", {
                        maximumFractionDigits: 0,
                        minimumFractionDigits: 0,
                      })
                    : "-"}
                </h3>
                <span className="unit"> kWh</span>
              </div>
              <div className="result-group">
                <div>
                  Egenforbruk av strøm
                  <Help inline>
                    <div className="help-text">
                      Dette er hvor mye av strømproduksjonen fra solcellene som
                      kan forventes å brukes internt i bygget. Det resterende
                      vil selges på nettet.
                    </div>
                  </Help>
                </div>
                <h3>
                  {selfUsage
                    ? selfUsage.toLocaleString("nb", {
                        maximumFractionDigits: 0,
                        minimumFractionDigits: 0,
                      })
                    : "-"}
                </h3>
                <span className="unit"> %</span>
              </div>
              <div className="result-group">
                <div>
                  Sparte klimagassutslipp
                  <Help inline>
                    <div className="help-text">
                      Som en referanse fører en flyreise fra Trondheim - Oslo
                      til 250 kg CO2-ekv.
                    </div>
                  </Help>
                </div>
                <h3>
                  {reducedEmissions
                    ? reducedEmissions.toLocaleString("nb", {
                        maximumFractionDigits: 0,
                        minimumFractionDigits: 0,
                      })
                    : "-"}
                </h3>
                <span className="unit"> tonn CO2-ekv</span>
              </div>
              <div className="result-group">
                <div>
                  Årlig strømforbruk til bygget
                  <Help inline>
                    <div className="help-text">
                      Dette baserer seg på det som er lagt inn under info om
                      bygget. Om dette avviker mye fra ditt faktiske
                      energiforbruk kan du enten endre energistandard eller
                      endre på antall m² for å få et mer representativt
                      energiforbruk.
                    </div>
                  </Help>
                </div>
                <h3>
                  {totalEnergyConsumption
                    ? totalEnergyConsumption.toLocaleString("nb", {
                        maximumFractionDigits: 0,
                        minimumFractionDigits: 0,
                      })
                    : "-"}
                </h3>
                <span className="unit"> kWh</span>
              </div>
            </HiddenContent>

            <button
              disabled={result.length === 0}
              className="btn primary"
              type="button"
              onClick={() => {
                downloadAsCsv(result);
              }}
            >
              <Icon name="download" />
              Last ned excel
            </button>

            {!filledOut && <div>Ikke alle felter er fylt ut</div>}
          </>
        )}
      </div>
    </div>
  );
}
