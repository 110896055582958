import NumberInput from "@avinet/adaptive-ui-core/form/controls/NumberInput";
import Page from "../components/Page";
import Lottie from "lottie-react";
import lifetime from "../assets/lifetime.json";
import "./Lifetime.scss";
import Row from "@avinet/adaptive-ui-core/layout/Row";
import Help from "../components/Help";

const id = "lifetime";

export default function Lifetime() {
  return (
    <Page>
      <h1>Levetid på solcelleanlegget</h1>
      <Row>
        <Row className="flex-item" align="bottom">
          <NumberInput
            id={id}
            locale="nb"
            type="integer"
            defaultValue="30"
            required
          />
          <span className="field-label">år</span>
        </Row>
        <Lottie className="lottie" animationData={lifetime} loop={false} />
      </Row>
      <Help title="Hva er gjennomsnittlig levetid?">
        <p>
          For skråtak kan man anta at solcelleanlegget har en levetid på 30 år.
          For flate tak vil levetiden sannsynligvis være noe kortere, vi
          anbefaler å forutsette maksimalt 25 år. Vi har inkludert kostnader for
          bytte av vekselretter i år 15 da dette som regel er levetiden på
          vekselrettere.
        </p>
      </Help>
    </Page>
  );
}
