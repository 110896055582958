import BuildingType from "./BuildingType";
import RoofType from "./RoofType";
import Effect from "./Effect";
import Price from "./Price";
import "./CalcPage.scss";
import Header from "../components/Header";
import Lifetime from "./Lifetime";
import Result from "./Result";
import Row from "@avinet/adaptive-ui-core/layout/Row";
import Grow from "@avinet/adaptive-ui-core/layout/Grow";
import av_logo from "../assets/av_logo.svg";

export default function CalcPage() {
  return (
    <div className="calc-page">
      <Header />
      <section>
        <div className="content">
          <BuildingType />
          <RoofType />
          <Effect />
          <Price />
          <Lifetime />
        </div>
        <Result />
      </section>
      <footer>
        <Row>
          <a href="https://www.asplanviak.no/" target="_blank" rel="noreferrer">
            <img src={av_logo} alt="asplan viak logo" />
          </a>
          <Grow />
          <a
            className="contact"
            href="https://av-solenergi.no/#nettkalkulator"
            target="_blank"
            rel="noreferrer"
          >
            Om løsningen
          </a>
          <Grow />
          <div className="contact">
            Kontaktinformasjon:
            <br />
            vidar.yttersian@asplanviak.no
          </div>
          <Grow />
        </Row>
      </footer>
    </div>
  );
}
