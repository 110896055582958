const degradation = 0.005;

export function ProductionAndConsumption(
  hourly: number[],
  ec: number[],
  roofShadow: number,
  area: number
) {
  const minLength = Math.min(ec.length, hourly.length);
  let productionSum = 0.0;
  let selfConsumptionSum = 0.0;
  let salesSum = 0.0;
  let consumptionSum = 0.0;

  for (let i = 0; i < minLength; i++) {
    const production = (roofShadow * hourly[i]) / 1000;
    const consumption = ec[i] * area;
    const selfConsumption = production < consumption ? production : consumption;
    const sales = production - selfConsumption;

    productionSum += production;
    selfConsumptionSum += selfConsumption;
    salesSum += sales;
    consumptionSum += consumption;
  }

  return { consumptionSum, productionSum, selfConsumptionSum, salesSum };
}

function Calculate(
  hourly: any,
  purchasePrice: number,
  roofShadow: number,
  ec: number[],
  area: number,
  lifetime: number,
  totalCostPower: number,
  capitalCost: number
) {
  const inverterReplacementCost = purchasePrice * 0.1;
  let resultTable = [];

  console.time("time");

  let { productionSum, selfConsumptionSum, salesSum } =
    ProductionAndConsumption(hourly, ec, roofShadow, area);

  productionSum = productionSum / 4.0;
  selfConsumptionSum = selfConsumptionSum / 4.0;
  salesSum = salesSum / 4.0;

  console.timeEnd("time");

  let totalDiscountedWithoutSales = -purchasePrice;
  let totalDiscountedWithSales = -purchasePrice;

  const year0 = {
    year: 0,
    production: 0,
    discountedIncomeWithoutSales: -purchasePrice,
    discountedIncomeWithSales: -purchasePrice,
    totalDiscountedWithoutSales: -purchasePrice,
    totalDiscountedWithSales: -purchasePrice,
  };

  resultTable.push(year0);

  let prodLifeTime = 0.0;
  let totalSelfConsumption = 0.0;

  for (let i = 1; i <= lifetime; i++) {
    const production = productionSum * (1.0 - degradation) ** i; //Show in result
    prodLifeTime += production;
    const selfConsumption = selfConsumptionSum * (1.0 - degradation) ** i;
    totalSelfConsumption += selfConsumption;
    const sales = salesSum * (1.0 - degradation) ** i;
    const incomeWithoutSales =
      production * totalCostPower - (i === 15 ? inverterReplacementCost : 0.0);
    const discountedIncomeWithoutSales =
      incomeWithoutSales * (1.0 + capitalCost) ** -i; //Show in result
    const incomeWithSales =
      selfConsumption * totalCostPower +
      (sales * totalCostPower) / 2.0 -
      (i === 15 ? inverterReplacementCost : 0.0);
    const discountedIncomeWithSales =
      incomeWithSales * (1.0 + capitalCost) ** -i; //Show in result
    totalDiscountedWithoutSales += discountedIncomeWithoutSales; //Show in result
    totalDiscountedWithSales += discountedIncomeWithSales; //Show in result
    const yearEl = {
      year: i,
      production,
      discountedIncomeWithoutSales,
      discountedIncomeWithSales,
      totalDiscountedWithoutSales,
      totalDiscountedWithSales,
    };
    resultTable.push(yearEl);
  }

  return {
    productionLifeTime: prodLifeTime,
    result: resultTable,
    totalSelfConsumption,
  };
}
export default Calculate;
