import NumberInput from "@avinet/adaptive-ui-core/form/controls/NumberInput";
import "../components/Page.scss";
import Help from "../components/Help";
import Lottie from "lottie-react";
import effect from "../assets/effect.json";
import Row from "@avinet/adaptive-ui-core/layout/Row";

const id = "effect";

export default function Effect() {
  return (
    <div className="page effect">
      <h1>Hvor mye effekt skal installeres?</h1>
      <Row>
        <Row className="flex-item" align="bottom">
          <NumberInput
            id={id}
            locale="nb"
            type="decimal"
            label={"Skriv inn din ønskede effekt"}
            required
          />
          <span className="field-label">kWp</span>
        </Row>
        <Lottie className="lottie" animationData={effect} loop={false} />
      </Row>
      <Help title="Hvordan regner jeg ut kWp?">
        <p>
          kWp, eller kilowatt peak, er hvor mye effekt som kan produseres ved
          maks solinnstråling fra solcelleanlegget, og er det tallet som er
          oppgitt fra leverandør. Et solcellepanel er typisk på 0,3-0,45 kWp, og
          vil dermed produsere 0,3-0,45 kW, eller 300-450 W ved maks
          solinnstråling.
        </p>
        <p>
          Dersom du ikke har fått et tilbud enda og er usikker, kan du bruke
          denne formelen: [tilgjengelig areal i m²] * 0,15 kWp for å beregne hva
          det er plass til på skråtak. På flatt tak kan man forutsette ca. 0,13
          kWp per m² på et tak med lite hindringer.
        </p>
      </Help>
    </div>
  );
}
