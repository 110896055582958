import AdaptiveUiTheme from "@avinet/adaptive-ui-core/adaptive-ui/AdaptiveUiTheme";

const theme: AdaptiveUiTheme = {
  name: "av-solenergi",
  fontStack: "Avenir Next LT Pro",
  fontWeight: 400,
  headingFontWeight: 600,
  labelTextColor: "#1d3c34",
  headingTextColor: "#1d3c34",
  primary: "#1d3c34",
  primaryContrast: "#B7DC8F",
  secondary: "#f0f4e3",
  secondaryContrast: "#1d3c34",
  tertiary: "#1d3c34",
  success: "#48a23f",
  successContrast: "#ffffff",
  warning: "#ffc358",
  danger: "#FB8500",
  dark: "#232420",
  medium: "#959690",
  light: "#f6f8f1",
  lightContrast: "#1d3c34",
  linkTextColor: "#1d3c34",
  buttonBorderWidth: 1,
  buttonPadding: "12px",
  inputBorderWidth: 0,
  inputBackground: "#F6F8F1",
  inputShadowOffset: "0px 0px",
  inputShadowBlur: 0,
  defaultBackground: "#F6F8F1",
  inputBorderColor: "#232420",
  headingFontSizes: ["28px", "28px", "26px"],
};

export default theme;
