import List from "@avinet/adaptive-ui-core/form/controls/List";
import { useMemo } from "react";
import Page from "../components/Page";
import boligblokk from "../assets/Boligblokk.svg";
import bolighus from "../assets/Bolighus.svg";
import industri from "../assets/Industri-logistikk.svg";
import kontor from "../assets/Kontor.svg";
import NumberInput from "@avinet/adaptive-ui-core/form/controls/NumberInput";
import Help from "../components/Help";
import { useFormApi } from "@avinet/adaptive-ui-core/form/useForm";
import Row from "@avinet/adaptive-ui-core/layout/Row";
import { Navigate } from "react-router-dom";

const remoteHeating = "remoteHeating";
const idCategory = "houseTypeCategory";
const idEnergyStandard = "energyStandard";
const idArea = "area";

export default function RoofType() {
  const { getValue } = useFormApi();
  const selectedAdress = getValue("selectedAddress");
  const address = selectedAdress?.address;
  const items = useMemo(
    () => [
      { value: "boligblokk", img: boligblokk },
      { value: "bolighus", img: bolighus },
      { value: "industri", img: industri },
      { value: "kontor", img: kontor },
    ],
    []
  );

  const tekItems = useMemo(
    () => [{ value: "tek97" }, { value: "tek10" }, { value: "passiv" }],
    []
  );

  const remoteHeatingItems = useMemo(
    () => [
      { value: "med_oppvarming" },
      { value: "varmepumpe" },
      { value: "uten_oppvarming" },
    ],
    []
  );

  if (!address) {
    return <Navigate to="/" />;
  }

  return (
    <Page>
      <h2>{address}</h2>
      <List
        className="building-type-list no-border"
        id={idCategory}
        langSet="buildingType"
        items={items}
        required
      />
      <List
        id={remoteHeating}
        langSet="remoteHeating"
        items={remoteHeatingItems}
        required
        layout="inline"
      />

      <List
        id={idEnergyStandard}
        langSet="EnergyStandard"
        items={tekItems}
        required
        layout="inline"
      />
      <Row align="bottom">
        <NumberInput id={idArea} locale="nb" type="integer" required={true} />
        <span className="field-label">m² BRA</span>
      </Row>

      <Help title="Utdypende forklaring">
        <p>
          Verdier og valg som gjøres her brukes for å anslå hvor høyt
          energiforbruket er per time gjennom et år, og da hvor mye av strømmen
          som produseres fra solcellene som vil brukes internt i bygget.
          Energiforbruket er basert på statistiske data fra SINTEF.
        </p>
        <p>
          Hvordan bygget er oppvarmet påvirker hvor mye strøm bygget bruker, og
          da videre hvor mye av strømproduksjonen fra solcellepanelene som kan
          brukes i bygget.
        </p>
        <p>
          TEK standard sier noe om hvor energieffektivt bygget er. «Eldre
          standard» representerer et gjennomsnitt av de bygg som er bygget før
          2000-tallet. TEK10/17 er alle nyere bygg, altså fra 2000 og frem til i
          dag. Passivhus er gjeldende for bygg som følger passivhusstandarden
          eller er veldig energieffektive.
        </p>
      </Help>
    </Page>
  );
}
