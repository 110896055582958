import { useEffect, useRef } from "react";
import { useFormApi } from "@avinet/adaptive-ui-core/form/useForm";
import roof from "../assets/gable_orientation.json";
import Lottie from "lottie-react";
import Help from "../components/Help";
import Column from "@avinet/adaptive-ui-core/layout/Column";
const id = "roofGableOrientation";

export default function RoofType() {
  const { getValue, setValue } = useFormApi();
  const lottieRef = useRef<any>(null);

  useEffect(() => {
    lottieRef.current.goToAndStop(180, true);
  }, []);

  return (
    <>
      <h2>Orientering</h2>
      <div className="flex-layout">
        <div className="flex-items">
          <p>
            Orienter taket ditt til hvordan solen vil treffe taket ditt midt på
            dagen ved å justere slideren.
          </p>
          <p>
            Orienteringen er vinkelen taket har når det peker mot solen, altså
            sør.
          </p>
        </div>
        <Column>
          <h2>{getValue(id)}°</h2>
          <div className="slider-container">
            <Lottie
              lottieRef={lottieRef}
              animationData={roof}
              loop={false}
              autoplay={false}
            />

            <input
              type="range"
              min="-180"
              max="180"
              value={getValue(id)}
              className="slider"
              onChange={(e) => {
                setValue(id, parseInt(e.target.value));
                lottieRef.current.goToAndStop(
                  parseInt(e.target.value) + 180,
                  true
                );
              }}
            />
          </div>
        </Column>
      </div>
      <Help title="Hva menes med orientering?">
        <p>
          Orientering er vinkelen på takets retning, hvor 0 grader er sør og 180
          grader er nord. Du kan sjekke ca retning ved å se på bygningen i
          Google maps.
        </p>
      </Help>
    </>
  );
}
