import Row from "@avinet/adaptive-ui-core/layout/Row";
import Grow from "@avinet/adaptive-ui-core/layout/Grow";
import logo from "../assets/logo.svg";
import "./Header.scss";
export default function Header() {
  return (
    <header>
      <Row>
        <img src={logo} alt="Page logo" />
        <Row className="wrap">
          <h1>
            <span className="uppercase">AV </span>
            <span className="regular-weight">Solkalkulator</span>
          </h1>
        </Row>
      </Row>
    </header>
  );
}
