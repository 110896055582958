import Icon from "@avinet/adaptive-ui-core/ui/Icon";
import "./AdvancedResultsDropDown.scss";
import { useState } from "react";

interface HiddenContentProps {
  title?: string;
  children: React.ReactNode;
  icon?: string;
  inline?: boolean;
}
export default function HiddenContent({
  title,
  children,
  icon,
}: HiddenContentProps) {
  const [hidden, setHidden] = useState(true);

  return (
    <div className="advanced-results-drop-down">
      {!hidden && children}
      <button
        className={hidden ? "btn-link no-padding" : "btn-link no-padding open"}
        type="button"
        onClick={() => setHidden((h) => !h)}
      >
        {icon && <Icon name={icon} />}
        {title && title}
        {<Icon name="chevron" rotate={hidden ? 270 : 90} />}
      </button>
    </div>
  );
}
