import Icon from "@avinet/adaptive-ui-core/ui/Icon";
import { useState } from "react";

interface HiddenContentProps {
  title?: string;
  children: React.ReactNode;
  icon?: string;
  inline?: boolean;
}
export function HiddenContent({
  title,
  children,
  icon,
  inline,
}: HiddenContentProps) {
  const [hidden, setHidden] = useState(true);

  return (
    <>
      <button
        className={
          inline ? "btn-link no-padding inline" : "btn-link no-padding"
        }
        type="button"
        onClick={() => setHidden((h) => !h)}
      >
        {icon && <Icon name={icon} />}
        {title && title}
        {!inline && <Icon name="chevron" rotate={hidden ? 90 : 270} />}
      </button>
      {!hidden && children}
    </>
  );
}

interface HelpProps {
  title?: string;
  children: React.ReactNode;
  inline?: boolean;
}
export default function Help({ title, inline, children }: HelpProps) {
  return (
    <HiddenContent title={title} icon="help" inline={inline}>
      {children}
    </HiddenContent>
  );
}
