import List from "@avinet/adaptive-ui-core/form/controls/List";
import { useFormApi } from "@avinet/adaptive-ui-core/form/useForm";
import { useMemo } from "react";
import no_shadow from "../assets/no_shadow.svg";
import more_shadow from "../assets/more_shadow.svg";
import even_more_shadow from "../assets/even_more_shadow.svg";
import Help from "../components/Help";
import "./RoofShadow.scss";
const id = "roofShadow";

export default function RoofType() {
  const items = useMemo(
    () => [{ value: 1.0 }, { value: 0.95 }, { value: 0.7 }],
    []
  );

  const { getValue } = useFormApi();
  const currentValue = getValue(id);
  const lottie = useMemo(() => {
    switch (currentValue) {
      case 1.0:
        return no_shadow;
      case 0.95:
        return more_shadow;
      case 0.7:
        return even_more_shadow;
    }
  }, [currentValue]);

  return (
    <div className="page roof-shadow">
      <h2>Skyggeforhold</h2>
      <div className="flex-layout">
        <List
          className="flex-items"
          langSet="RoofGableShadow"
          label={null}
          id={id}
          items={items}
        />
        <img src={lottie} alt="" />
      </div>
      <Help title="Forklar litt mer om skyggeforhold">
        <p>
          Skygge fra omkringliggende landskap (fjell, hauger o.l.) er allerede
          inkludert basert på adressen til bygget. Skyggeforhold som legges inn
          her er skygge på grunn av oppstikk på eget tak (ventilasjonsrom og
          luftekanaler), andre omkringliggende bygg eller trær. Ved plassering
          av solcellepanelene på taket er skygge en viktig faktor å tenke på.
          Noe skygge kan velges om en liten del av taket har skygge opptil 8
          timer av dagen om sommeren, mens mye skygge velges dersom en større
          del av taket har skygge i 8 timer eller mer om dagen.
        </p>
      </Help>
    </div>
  );
}
