import Result from "./types/Result";

type ISomeTypeA = { [key: string]: string };

const mapping: ISomeTypeA = {
  year: "År",
  production: "Energi-produksjon (kWh)",
  discountedIncomeWithoutSales:
    "Inntekter og utgifter per år, om alt av strømproduksjon brukes internt",
  discountedIncomeWithSales:
    "Inntekter og utgifter per år, inkludert salg av strøm (kr)",
  totalDiscountedWithoutSales:
    "Inntekter/utgifter akkumulert over levetiden, om alt av strømproduksjon brukes internt (kr)",
  totalDiscountedWithSales:
    "Inntekter/utgifter akkumulert over levetiden inkludert salg av strøm (kr)",
};
const separator = ";";
const getHeaders = (row: Result) => {
  const keys = Object.keys(row);
  return keys.map((k) => mapping[k]);
};
const arrayToCsv = (data: Result[]) => {
  let csvRows = [getHeaders(data[0]).join(separator)];

  const values = data.map((r) => {
    return Object.values(r).join(separator);
  });

  csvRows.push(...values);

  return csvRows.join("\n");
};

const downloadAsCsv = (data: Result[]) => {
  const csv = arrayToCsv(data); // Creating a Blob for having a csv file format
  // and passing the data with type
  var BOM = "\uFEFF";
  const blob = new Blob([BOM + csv], { type: "text/csv;charset=utf-8" });

  // Creating an object for downloading url
  const url = window.URL.createObjectURL(blob);

  // Creating an anchor(a) tag of HTML
  const a = document.createElement("a");

  // Passing the blob downloading url
  a.setAttribute("href", url);

  // Setting the anchor tag attribute for downloading
  // and passing the download file name
  a.setAttribute("download", "download.csv");

  // Performing a download with click
  a.click();
};

export default downloadAsCsv;
